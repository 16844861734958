import React, { ReactNode } from 'react';

import { GlobalStyles } from '../styles/GlobalStyles';

const Layout = (
  { children }: { children: ReactNode }
) => (
  <>
    <GlobalStyles />
    <div className="wrapper">
      {children}
    </div>
  </>
);

export default Layout;
