import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  body {
    background: #fff;
  }

  .wrapper {
    padding: 32px;
  }

  .hidden {
    visibility: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 18px 0;

    &:first-of-type {
      padding-top: 0;
    }
  }

  a {
    color: #000;
    font-family: 'Fira Mono', monospace;
    font-size: 32px;
    text-decoration: none;

    &:hover {
      color: #666;
    }
  }

  .longTitle {
    font-size: 24px;
  }

  @media (max-width:768px) {
    a {
      font-size: 28px;
    }

    .longTitle {
      font-size: 20px;
    }
  }

  @media (max-width:590px) {
    .wrapper {
      padding: 24px;
    }

    li {
      list-style: none;
      padding: 12px 0;

      &:first-of-type {
        padding-top: 0;
      }
    }

    a {
      font-size: 22px;
    }

    .longTitle {
      font-size: 16px;
    }
  }

  @media (max-width:460px) {
    .wrapper {
      padding: 16px;
    }

    a {
      font-size: 18px;
    }

    .longTitle {
      font-size: 12px;
    }
  }
`;

export { GlobalStyles };
